

.highcharts-yaxis-grid .highcharts-grid-line {
	stroke-width: 0px;
	stroke: #d8d8d8;
}

.highcharts-background {
    fill: white;
	stroke: rgba(0,0,0,0);
}