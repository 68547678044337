$red: rgb(1, 42, 95);
$white: #fff;


/* -------------------------------- 

Primary style

-------------------------------- */
// *, *::after, *::before {
//     box-sizing: border-box; 
//   }
  
//   html { 
//     font-size: 62.5%;
//   }
  
//   body {
//     font-size: 1.6rem;
//     font-family: "Open Sans", sans-serif;
//     color: #040404;
//     background-color: #ffffff;
//   }
  
//   a {
//     color: rgb(1, 42, 95);
//     text-decoration: none;
//   }
  
  .landing-page {
  /* -------------------------------- 
  
  General Style
  
  -------------------------------- */
  .cd-intro {
    position: relative;
    height: 100vh;
    width: 100%;
    /* vertically align its content */
    display: table;
    background: rgb(1, 42, 95);
    overflow: hidden;
  }
  
  .cd-intro-content {
    /* vertically align inside its parent */
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 0 5%;
    background: rgb(1, 42, 95);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .cd-intro-content h1 {
    font-size: 2.4rem;
    color: #ffffff;
  }
  .cd-intro-content p {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
    margin: 1em 0;
    color: #6d7a97;
  }
  .cd-intro-content .action-wrapper {
    display: inline-block;
  }
  .cd-intro-content .action-wrapper::after {
    clear: both;
    content: "";
    display: table;
  }
  .cd-intro-content .action-wrapper > * {
    float: left;
  }
  @media only screen and (min-width: 768px) {
    .cd-intro-content h1 {
      font-size: 5.2rem;
      font-weight: 300;
    }
    .cd-intro-content p {
      font-size: 1.6rem;
      margin: 1.5em 0 1.9em;
    }
  }
  
  .cd-btn {
    display: inline-block;
    padding: 1.2em 1.4em;
    font-size: 1.3rem;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: #202e4a;
    border-radius: .25em;
    margin-right: 1.5em;
  }
  .cd-btn:nth-of-type(2) {
    margin-right: 0;
  }
  .cd-btn.main-action {
    background-color: #fb5e58;
  }
  @media only screen and (min-width: 480px) {
    .cd-btn {
      padding: 1.2em 1.6em;
    }
  }
  @media only screen and (min-width: 768px) {
    .cd-btn {
      padding: 1.4em 1.8em;
    }
  }
  
  .cd-intro-content h1,
  .cd-intro-content h1 span,
  .cd-intro-content p,
  .cd-intro-content .cd-btn {
    opacity: 0;
    -webkit-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .no-cssanimations .cd-intro-content h1, .no-cssanimations
  .cd-intro-content h1 span, .no-cssanimations
  .cd-intro-content p, .no-cssanimations
  .cd-intro-content .cd-btn {
    opacity: 1;
  }
  
  
  /* -------------------------------- 
  
  Mask 2
  
  -------------------------------- */
  .mask-2.cd-intro-content * {
    /* overwrite default style */
    opacity: 1;
  }
  
  .mask-2.cd-intro-content .content-wrapper {
    position: relative;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding: 2em 0;
    overflow: hidden;
  }
  .mask-2.cd-intro-content .content-wrapper::before {
    /* vertical bar */
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    background-color: #ffffff;
    -webkit-animation: cd-pulse 1.3s 0.3s both;
    -moz-animation: cd-pulse 1.3s 0.3s both;
    animation: cd-pulse 1.3s 0.3s both;
  }
  .no-cssanimations .mask-2.cd-intro-content .content-wrapper::before {
    opacity: 0;
  }
  .mask-2.cd-intro-content .content-wrapper > div {
    /* wrap the entire content */
    position: relative;
    z-index: 1;
  }
  
  .mask-2.cd-intro-content .content-wrapper,
  .mask-2.cd-intro-content .content-wrapper > div {
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-delay: 1.1s;
    -moz-animation-delay: 1.1s;
    animation-delay: 1.1s;
    -webkit-animation-fill-mode: backwards;
    -moz-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
  }
  
  .mask-2.cd-intro-content .content-wrapper {
    -webkit-animation-name: cd-mask-wrapper;
    -moz-animation-name: cd-mask-wrapper;
    animation-name: cd-mask-wrapper;
  }
  
  .mask-2.cd-intro-content .content-wrapper > div {
    -webkit-animation-name: cd-mask-content;
    -moz-animation-name: cd-mask-content;
    animation-name: cd-mask-content;
  }
  
  @-webkit-keyframes cd-mask-wrapper {
    0% {
      -webkit-transform: translateX(50%);
    }
    100% {
      -webkit-transform: translateX(0);
    }
  }
  @-moz-keyframes cd-mask-wrapper {
    0% {
      -moz-transform: translateX(50%);
    }
    100% {
      -moz-transform: translateX(0);
    }
  }
  @keyframes cd-mask-wrapper {
    0% {
      -webkit-transform: translateX(50%);
      -moz-transform: translateX(50%);
      -ms-transform: translateX(50%);
      -o-transform: translateX(50%);
      transform: translateX(50%);
    }
    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @-webkit-keyframes cd-mask-content {
    0% {
      -webkit-transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(0);
    }
  }
  @-moz-keyframes cd-mask-content {
    0% {
      -moz-transform: translateX(-100%);
    }
    100% {
      -moz-transform: translateX(0);
    }
  }
  @keyframes cd-mask-content {
    0% {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @-webkit-keyframes cd-pulse {
    0%, 30.8% {
      opacity: 0;
    }
    15.4%, 46.2%, 61.5%, 70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes cd-pulse {
    0%, 30.8% {
      opacity: 0;
    }
    15.4%, 46.2%, 61.5%, 70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes cd-pulse {
    0%, 30.8% {
      opacity: 0;
    }
    15.4%, 46.2%, 61.5%, 70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  
  /* -------------------------------- 
  
  Mask
  
  -------------------------------- */
  .mask.cd-intro-content h1 {
    position: relative;
    padding-bottom: 10px;
    /* overwrite default style */
    opacity: 1;
    font-weight: 700;
    /* <h1> text is not visible - it is used only as a container for the ::after element */
    color: transparent;
    overflow: hidden;
  }
  .mask.cd-intro-content h1::after {
    /* this is the animated text */
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #ffffff;
    -webkit-animation-name: cd-reveal-up;
    -moz-animation-name: cd-reveal-up;
    animation-name: cd-reveal-up;
    -webkit-animation-fill-mode: backwards;
    -moz-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
  }
  .mask.cd-intro-content h1 span {
    position: relative;
    display: inline-block;
    opacity: 1;
  }
  .mask.cd-intro-content h1 span::before {
    /* this is the loading bar */
    content: '';
    position: absolute;
    top: calc(100% + 8px);
    left: -1em;
    height: 2px;
    width: calc(100% + 2em);
    background-color: #ffffff;
    -webkit-animation: cd-loading-mask 1s 0.3s both;
    -moz-animation: cd-loading-mask 1s 0.3s both;
    animation: cd-loading-mask 1s 0.3s both;
  }
  @media only screen and (min-width: 768px) {
    .mask.cd-intro-content h1 {
      padding-bottom: 20px;
    }
    .mask.cd-intro-content h1 span::before {
      top: calc(100% + 18px);
    }
  }
  
  .mask.cd-intro-content p {
    position: relative;
    margin: 0;
    padding: 10px 0 0;
    -webkit-animation-name: cd-reveal-down;
    -moz-animation-name: cd-reveal-down;
    animation-name: cd-reveal-down;
  }
  @media only screen and (min-width: 768px) {
    .mask.cd-intro-content p {
      padding-top: 20px;
    }
  }
  
  .mask.cd-intro-content h1::after,
  .mask.cd-intro-content p {
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-delay: 0.7s;
    -moz-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  
  .mask.cd-intro-content .action-wrapper {
    overflow: hidden;
  }
  .mask.cd-intro-content .action-wrapper .cd-btn {
    opacity: 1;
    margin: 0 0 0 1.5em;
  }
  .mask.cd-intro-content .action-wrapper .cd-btn:first-of-type {
    margin-left: 0;
  } 
  
  @-webkit-keyframes cd-loading-mask {
    0%, 100% {
      -webkit-transform: scaleX(0);
    }
    40%, 60% {
      -webkit-transform: scaleX(1);
    }
  }
  @-moz-keyframes cd-loading-mask {
    0%, 100% {
      -moz-transform: scaleX(0);
    }
    40%, 60% {
      -moz-transform: scaleX(1);
    }
  }
  @keyframes cd-loading-mask {
    0%, 100% {
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
      -ms-transform: scaleX(0);
      -o-transform: scaleX(0);
      transform: scaleX(0);
    }
    40%, 60% {
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -o-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    text-align: center; 
    
    @media (min-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
    }

    h1 {
        color: $white;
        font-size: 1.25em;
        font-weight: 900;
        margin: 0 0 2em;
        
        @media (min-width: 450px) {
          font-size: 1.75em;
        }
        
        @media (min-width: 760px) {
          font-size: 3.25em;
        }
        
        @media (min-width: 900px) {
          font-size: 5.25em;
          margin: 0 0 1em;
        }
      }
      
      p {
        color: $white;
        font-size: 12px;
        
        @media(min-width: 600px) {
          left: 50%;
          position: absolute; 
          transform: translate(-50%, 0);
          top: 90%;
        }
        
        @media(max-height: 500px) {
          left: 0;
          position: relative;
          top: 0;
          transform: translate(0, 0);
        }
        
        a {
          background: rgba($white, 0);
          border-bottom: 1px solid;
          color: $white;
          line-height: 1.4;
          padding: .25em;
          text-decoration: none;
          
          &:hover {
            background: rgba($white, 1);
            color: $red;
          }
        }
      }
      //button styles
      
      
      //default button
      .btn {
        color: #fff;
        cursor: pointer;
        // display: block;
        font-size:16px;
        font-weight: 400;
        line-height: 45px;
        margin: 0 0 2em;
        max-width: 160px; 
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%; 
        
      //   @media(min-width: 400px) {
      //     display: inline-block;
      //     margin-right: 2.5em;
        
      //     &:nth-of-type(even) {
      //       margin-right: 0;
      //     }
      //   }
        
        @media(min-width: 600px) {
            
          margin: 0 1em 2em;
        
      //     &:nth-of-type(even) {
      //       margin-right: 2.5em;
      //     }
          
      //     &:nth-of-type(5) {
      //       margin-right: 0;
      //     }
          
        }
        
        &:hover { text-decoration: none; }
        
      }
      
      /////////////////////////////////
      //button one
      ///////////////////////////////
      .btn-1 {
        background: darken($red, 1.5%);
        font-weight: 100;
        
        svg {
          height: 45px;
          left: 0;
          position: absolute;
          top: 0; 
          width: 100%; 
        }
        
        rect {
          fill: none;
          stroke: #fff;
          stroke-width: 2;
          stroke-dasharray: 422, 0;
          transition: all 0.35s linear;
        }
      }
      
      .btn-1:hover {
        background: rgba($red, 0);
        font-weight: 900;
        letter-spacing: 1px;
        
        rect {
          stroke-width: 5;
          stroke-dasharray: 15, 310;
          stroke-dashoffset: 48;
          transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
      
      ////////////////////////////
      //button two
      //////////////////////////
      .btn-2 {
          letter-spacing: 0;
      }
      
      .btn-2:hover,
      .btn-2:active {
        letter-spacing: 5px;
      }
      
      .btn-2:after,
      .btn-2:before {
        backface-visibility: hidden;
        border: 1px solid rgba(#fff, 0);
        bottom: 0px;
        content: " ";
        display: block;
        margin: 0 auto;
        position: relative;
        transition: all 280ms ease-in-out;
        width: 0;
      }
      
      .btn-2:hover:after,
      .btn-2:hover:before {
        backface-visibility: hidden;
        border-color: #fff;
        transition: width 350ms ease-in-out;
        width: 70%;
      }
      
      .btn-2:hover:before {
        bottom: auto;
        top: 0;
        width: 70%;
      }
      
      /////////////////////////////
      //button -3 
      ///////////////////////////
      .btn-3 {
        background: lighten($red, 3%);  
        border: 1px solid darken($red, 4%);
        box-shadow: 0px 2px 0 darken($red, 5%), 2px 4px 6px darken($red, 2%);
        font-weight: 900;
        letter-spacing: 1px;
        transition: all 150ms linear;
      }
      
      .btn-3:hover {
        background: darken($red, 1.5%);
        border: 1px solid rgba(#000, .05);
        box-shadow: 1px 1px 2px rgba(#fff, .2);
        color: lighten($red, 18%); 
        text-decoration: none;
        text-shadow: -1px -1px 0 darken($red, 9.5%);
        transition: all 250ms linear;
      }
      
      /////////////////////////////
      //button-4 
      ///////////////////////////
      .btn-4 {
        border: 1px solid;
        overflow: hidden;
        position: relative;
        
        span {
          z-index: 20;
        }
        
        &:after {
          background: #fff;
          content: "";
          height: 155px;
          left: -75px;
          opacity: .2;
          position: absolute;
          top: -50px;
          transform: rotate(35deg);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          width: 50px;
          z-index: -10;
        }
      }
      
      .btn-4:hover {
        
        &:after {
          left: 120%;
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
      
      /////////////////////////////
      //button-5 
      ///////////////////////////
      .btn-5 {
        border: 0 solid;
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
        outline: 1px solid;
        outline-color: rgba(255, 255, 255, .5);
        outline-offset: 0px;
        text-shadow: none;
        transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
      } 
      
      .btn-5:hover {
        border: 1px solid;
        box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
        outline-color: rgba(255, 255, 255, 0);
        outline-offset: 15px;
        text-shadow: 1px 1px 2px #427388; 
      }
}

.container {
    justify-content: center;
}
  }
